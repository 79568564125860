<template>
  <div id="supplier">
      <!-- <pre-project></pre-project> -->
      <PreProjectLandingPage></PreProjectLandingPage>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PreProject from '@components/supplier/projects/PreProject.vue'
import PreProjectLandingPage from "@components/supplier/projects/PreProjectLandingPage.vue";
export default {
  name: "preproject-page",
  data() {
    return {
      project: "nuevo",
      status: "",
      allowed: null,
      loading: false,
    };
  },
  components: {
    PreProject,
    PreProjectLandingPage
  },
  computed: {
    ...mapState("auth", ["user"]),
    userId: function() {
      return this.user === null ? 0 : this.user.user_id;
    },
    supplierStatus: function() {
      return this.user === null ? 0 : this.user.supplier_status;
    }
  }
};
</script>

allowed="{{$data['verified']}}"