<template>
    <div id="landing-preproject">
        <div class="vx-row">
            <div class="vx-col w-full align-center">
                <vx-card class="sm:p-base">
                    <div class="text-xl black sm:mb-base md:mb-20">
                        <p class="mb-4">
                            Ahora tus clientes pueden generar sus propias solicitudes de crédito. <span class="bold">Ya
                                no es necesario que tú crees el proyecto por ellos.</span>
                        </p>
                        <p class="mb-base">
                            Comparte tu código a tus clientes y se te asignará automáticamente el proyecto cuando hagan
                            su solicitud.
                        </p>
                    </div>
                    <div class="vx-row black mb-base">
                        <div class="step md:w-1/3 sm:w-full vx-col mb-base">
                            <h2 class="mb-base">1. Comparte</h2>
                            <img style="width: 20rem;" class="mb-base"
                                :src="`${StorageURL}/suppliers/resources/Comparte.png`" alt="Comparte">
                            <p class="mt-16 text-lg">Envía el código a tu cliente.</p>
                        </div>
                        <div class="step md:w-1/3 sm:w-full vx-col mb-base">
                            <h2 class="mb-base">2. Solicita</h2>
                            <img style="width: 10rem;" class="mb-base"
                                :src="`${StorageURL}/suppliers/resources/Solicita.png`" alt="Solicita">
                            <p class="mt-12 text-lg">El proyecto se te asignará automáticamente <br> cuando tu cliente complete
                                la solicitud. </p>
                        </div>
                        <div class="step md:w-1/3 sm:w-full vx-col mb-base">
                            <h2 class="mb-base">3. Complementa</h2>
                            <img style="width: 10rem;" class="mb-base"
                                :src="`${StorageURL}/suppliers/resources/Complementa.png`" alt="Complementa">
                            <p class="mt-12 text-lg">Complementa la información de instalación <br> cuando se te asigne el
                                proyecto. </p>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="flex">
                                <vs-button @click.stop="sendWPP" icon-pack="feather" icon="icon-share" class="mr-4">Compartir</vs-button>
                                <vs-button @click.stop="myFunction" icon-pack="feather" icon="icon-copy">Copiar</vs-button>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingPreProject",
    data: () => ({
    }),
    props: {
        source: String
    },
    computed: {
        supplierReferredUrl() {
            return `${this.AppDomain}/registro/solicitante?r=${this.SupplierReferralCode}`;
        },
        messageWpp() {
            return `Solicita tu Crédito Solar en RedGirasol *obtén financiamiento para tus paneles solares* para tu casa o negocio. Aprovecha la energía del sol y *ahorra hasta 95% de tu recibo de luz*.
            %0A
            %0AEmpieza tu registro aquí: ${this.supplierReferredUrl}`;
        },
        message() {
            return `Solicita tu Crédito Solar en RedGirasol *obtén financiamiento para tus paneles solares* para tu casa o negocio. Aprovecha la energía del sol y *ahorra hasta 95% de tu recibo de luz*.

Empieza tu registro aquí: ${this.supplierReferredUrl}`;
        }
    },
    methods: {
        myFunction() {
            // Copy the text inside the text field
            navigator.clipboard.writeText(this.message);

            this.saveSuccessNotif(null, "Texto copiado correctamente.");
        },
        async sendWPP() {
            window.open(`https://wa.me/?text=${this.messageWpp}`, "_blank")
        },
    }
}
</script>

<style scoped>
.step {
    text-align: center;
    text-align: -webkit-center;
}
</style>