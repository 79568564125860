<template>
  <div>
    <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
              <h1 class="rg-big-title extrabold rg-title">Nuevo<span class="orange"> proyecto</span>.</h1>
              <p class="text-subtitle mt-4">En esta sección podrás crear una pre-solicitud de crédito, en tres simples pasos.</p>
          </div>
        </div>
        <!-- <vs-button class="mr-4" @click="initIdentityVerificationTimer()">Terminó con éxito mati</vs-button>
        <vs-button class="mr-4" @click="identityVerificationHasExpired = true">Expiró la verificación</vs-button>
        <vs-button @click="checkFormFieldsSpecialValidations('step2')">Correr validaciones</vs-button> -->
    </vx-card>

    <vx-card v-if="!failed" :title="title">
      <vs-alert color="dark" active="true" style="height:auto">
        Es importante que brindes información real del cliente, ya que eso facilitará la tarea de validación y <strong>tu proyecto podrá llegar más rápido a la fase de fondeo</strong>.
      </vs-alert>
    
      <form-wizard
        ref="wizard"
        color="rgba(var(--vs-primary), 1)"
        errorColor="rgba(var(--vs-danger), 1)"
        :title="null"
        :subtitle="null"
        backButtonText="Atrás"
        nextButtonText="Continuar"
        finishButtonText="Enviar"
        @on-complete="formSubmitted" >

        <tab-content
          title="Solicitante nuevo o existente"
          class="mb-5"
          icon="feather icon-home"
          :before-change="validateSourceType"
        >
          <!-- tab 1 content -->
          <form data-vv-scope="step-0">
            <div class="vx-row">
              <div class="vx-col w-full mt-5">
                <section class="mb-4">
                  <h6 class="mb-3">¿Deseas crear el proyecto para un solicitante nuevo o existente?</h6>
                  <div class="vx-row mb-4">
                    <div class="vx-col md:w-1/2 w-full">

                      <v-select
                        name="sourceType"
                        label="text"
                        class="vs-custom w-full select-large"
                        v-model.number="sourceType"
                        v-validate="'required'"
                        :options="collections.sourceTypes"
                        :reduce="text => text.type "
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción"
                        :disabled="!canSelectExistingApplicant"    
                      >
                      </v-select>
                      <v-error v-if="errors.has('step-0.sourceType')" :error="errors.first('step-0.sourceType')" />

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </form>
          <div v-if="has_pgm_potential_applicants" class="vx-col w-full mt-5">
            <section class="mb-6">
              <h6 class="mb-1">¿La solicitud está relacionada con algún prospecto del programa Coca-Cola FEMSA?</h6>
              <p class="mb-1">
                Sólo puedes elegir algún prospecto que se encuentran en los estados <strong>Interesado en una propuesta</strong> y <strong>Con propuesta realizada.</strong>
              </p>
              <div class="vx-row mb-4">
                <div class="vx-col md:w-1/2 w-full">

                  <v-select
                    name="pgmPotentialApplicant"
                    label="text"
                    class="vs-custom w-full select-large"
                    v-model.number="payload.customer_id"
                    :options="pgm_potential_applicants_options"
                    :reduce="text => text.value "
                    :clearable="false"
                    :searchable="false"
                    placeholder="Seleccione una opción"   
                  >
                  </v-select>
                  <v-error v-if="errors.has('step-0.pgmPotentialApplicant')" :error="errors.first('step-0.pgmPotentialApplicant')" />

                </div>
              </div>
            </section>
          </div>
        </tab-content>

        <tab-content
          :title="secondTabTitle"
          class="mb-5"
          icon="feather icon-user"
          :before-change="validateSelectType"
        >
          <!-- tab 1 content -->
          <form data-vv-scope="step-1">
            <div class="vx-row">
              <div class="vx-col w-full mt-5">
                <section class="mb-6" v-if="sourceType == sourceTypeNew">
                  <h6 class="mb-3">{{selectField.field_name}}</h6>
                  <div class="vx-row mb-4">
                    <div class="vx-col md:w-1/2 w-full">
                      <v-select
                        name="person_type"
                        label="text"
                        class="vs-custom w-full select-large"
                        v-model.number="payload.person_type"
                        v-validate="'required'"
                        :options="collections.personTypes"
                        :reduce="text => text.type "
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción" 
                      >
                      </v-select>
                      <v-error v-if="errors.has('step-1.person_type')" :error="errors.first('step-1.person_type')" />
                    </div>
                  </div>
                </section>

                <section class="mb-6" v-if="sourceType == sourceTypeExisting">
                  <h6 class="mb-3">Seleccione al solicitante para el que desea crear el proyecto</h6>
                  <div class="vx-row mb-4">
                    <div class="vx-col md:w-3/4 sm:w-full w-full">

                      <v-select
                        name="existingApplicantId"
                        label="fullname"
                        class="vs-custom w-full select-large"
                        v-model.number="existingApplicantId"
                        v-validate="'required'"
                        :options="clientsList"
                        :reduce="fullname => fullname.id "
                        :clearable="false"
                        :searchable="false"
                        placeholder="Seleccione una opción" 
                      >
                      </v-select>
                      <v-error v-if="errors.has('step-1.existingApplicantId')" :error="errors.first('step-1.existingApplicantId')" />
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </form>
<!-- ddimas -->
          <div class="vx-row" v-if="sourceType != sourceTypeExisting && payload.person_type == pfTypeValue && (SupplierId == 2323)">
            <div class="vx-col sm:w-full md:w-1/2 lg:w1/2">
              <h6 class="mb-4">¿Cómo quieres iniciar tu solicitud?</h6>
              <div class="vx-col w-full mb-6">
                <ul class="centerx">
                  <li>
                    <vs-radio color="primary" v-model.number="requestTypeSelected" vs-value="1">Solicitud Normal</vs-radio>
                  </li>
                  <li>
                    <vs-radio id="express-option" color="primary" v-model.number="requestTypeSelected" vs-value="0">Solicitud Exprés <vs-chip color="primary" class="ml-2 float-right">¡Nuevo!</vs-chip></vs-radio>
                  </li>
                </ul>
              </div>
            </div>
            <div class="vx-col sm:w-full md:w-1/2 lg:w1/2">
              <h6 class="mb-4">A tomar en cuenta:</h6>
              <ul class="centerx ul-bullet">
                <template v-if="requestTypeSelected == 0">
                  <li>Tu Cliente debe estar presente con su credencial de elector y celular.</li>
                  <li>Se realiza la verificación de identidad y consulta de historial crediticio en tiempo real.</li>
                  <li>Tiempo de respuesta de <strong>pre-aprobación en minutos</strong>.</li>
                </template>
                <template v-else>
                  <li>Llenado de información de manera manual.</li>
                  <li>Tiempo de respuesta de pre-aprobación en 1 a 2 días.</li>
                </template>
              </ul>
            </div>
          </div>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content
          v-if="!isPartialCreation"
          title="Llenado de pre-solicitud"
          class="mb-5"
          icon="feather icon-briefcase"
          :before-change="savePreproject">

          <form v-if="!projectIsCreated" data-vv-scope="step-2">
            <vx-card v-if="section.id != 32 && section.id != 16" v-for="section in formSections" :key="section.id" :title="section.public_name" class="mb-5">
                <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>

              <div class="vx-row">
                <p v-if="section.id == 47 && applicant_is_pf" class="vx-col w-full mb-4 ml-6 mr-6">Como solicitante tipo <strong>"Persona Física"</strong> podemos financiar hasta <strong>{{mxnFormat(maxUdisPF)}}</strong>. En caso de <strong>"Actividad Empresarial"</strong> puede ser un monto mayor.</p>
                <vs-alert color="primary" v-if="section.id == 32 || section.id == 16" class="mb-4 ml-4 mr-6">
                  <span class="text-justify"><strong>Más adelante te pediremos el predial/escritura de la ubicación del proyecto.</strong>
                  en caso de que la ubicación del proyecto sea el domicilio del solicitante, asegúrate que la información que ingrreses a continuación coincida 
                  con el documento anteriormente mencionado. De lo contrario, en su momento deberás contactar a tu ejecutivo de cuenta para solicitar un ajuste.</span>
                </vs-alert>
                <template v-for="f in section.fields">
                  <!-- Campos automaticos -->
                  <template v-if="requestTypeSelected == 0">
                    <template v-if="section.id == 47 || section.id == 14">
                      <form-field 
                        ref="step2"
                        v-if="isAutoFormField(f) && (f.db_field == 'total_cost' || f.db_field == 'phone' || f.db_field == 'country_calling_code' || f.db_field == 'email')"
                        :key="f.id"
                        :f="f"
                        scope="step-2"
                        :dataContainer="payload"
                        :collectionsRoot="collections"
                        :onChangeReceptor="onFieldChange"
                        :evaluator="evaluateCondition" />
                    </template>
                    <template v-else>
                      <form-field 
                        ref="step2"
                        v-if="isAutoFormField(f)"
                        :key="f.id"
                        :f="f"
                        scope="step-2"
                        :dataContainer="payload"
                        :collectionsRoot="collections"
                        :onChangeReceptor="onFieldChange"
                        :evaluator="evaluateCondition" />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="section.id == 47">
                        <form-field 
                          ref="step2"
                          v-if="isAutoFormField(f) && (f.db_field == 'total_cost')"
                          :key="f.id"
                          :f="f"
                          scope="step-2"
                          :dataContainer="payload"
                          :collectionsRoot="collections"
                          :onChangeReceptor="onFieldChange"
                          :evaluator="evaluateCondition" />
                    </template>
                    <template v-else>
                      <form-field 
                        ref="step2"
                        v-if="isAutoFormField(f) && showFormFields(f.db_field)"
                        :key="f.id"
                        :f="f"
                        scope="step-2"
                        :dataContainer="payload"
                        :collectionsRoot="collections"
                        :onChangeReceptor="onFieldChange"
                        :evaluator="evaluateCondition" />
                    </template>
                  </template>
                </template>
              </div>
            </vx-card>
          </form>

          <div v-else-if="requestTypeSelected == 0 && (SupplierId == 2323)">
            <vx-card class="mb-5" v-if="!identityVerificationHasExpired && needIdentityVerification">
              <h6 class="mb-3">Verificación de identidad</h6>
              <div class="vx-row m-0">
                <applicant-identity-verification
                  ref="applicant-identity-verification"
                  :isMoral="!applicant_is_pf"
                  :userId="current_user_id"
                  :person-type="payload.person_type"
                  />
              </div>
              <div v-if="createdClient != null">
                <vs-divider class="m-4">Información personal</vs-divider>
                <div class="vx-row p-4">
                  <div class="vx-col w-full">
                    <div class="vx-row sm:w-full md:w-1/2">
                      <ul class="list-bold">
                        <li>
                            Nombre: <strong>{{ client_personal.first_name }} {{ client_personal.second_name }}</strong>
                        </li>
                        <li>
                            Apellido Paterno: <strong>{{ client_personal.last_name_1 }}</strong>
                        </li>
                        <li>
                            Apellido Materno: <strong>{{ client_personal.last_name_2 }}</strong>
                        </li>
                        <li>
                            Correo: <strong>{{ createdClient.user.email }}</strong>
                        </li>
                        <li>
                            Teléfono: <strong>({{ client_personal.country_calling_code }}) {{ client_personal.new_phone }}</strong>
                        </li>
                      </ul>
                    </div>
                    <div class="vx-row sm:w-full md:w-1/2">
                      <ul class="list-bold">
                        <li>
                            Fecha de nacimiento: <strong>{{ client_personal.birth_date }}</strong>
                        </li>
                        <li>
                            Género: <strong>{{ client_personal.real_gender }}</strong>
                        </li>
                        <li>
                            CURP: <strong>{{ client_personal.curp }}</strong>
                        </li>
                        <li>
                            Nacionalidad: <strong>{{ client_personal.citizenship_named }}</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>

            <template v-else>
              <vx-card class="mb-6">
                <form data-vv-scope="step-2">
                  <vx-card v-if="section.id == 14" v-for="section in formSections" :key="section.id" :title="section.public_name" class="mb-5">
                    <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>
                    <vs-alert color="danger" v-if="section.id == 14" class="mb-4 mr-6">
                      <span class="text-justify">
                        <strong>Parece que nos tomará más tiempo del esperado.</strong><br>
                        Para continuar con el proceso de la solicitud exprés, te pedimos llenes la información personal de tu cliente y le pidas su autorización para
                        que podamos consultar su historial crediticio. ¡Recuerda guardar cambios cuando estés listo!
                      </span>
                    </vs-alert>
                    <div class="vx-row">
                      <template v-for="f in section.fields">
                        <!-- Campos automaticos -->
                        <template v-if="section.id == 14">
                            <form-field 
                              ref="step2"
                              v-if="isAutoFormField(f)"
                              :key="f.id"
                              :f="f"
                              scope="step-2"
                              :dataContainer="payload"
                              :collectionsRoot="collections"
                              :onChangeReceptor="onFieldChange"
                              :evaluator="evaluateCondition" />
                          </template>
                      </template>
                    </div>
                    <!-- Save Button -->
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <div class="flex flex-wrap items-center justify-end">
                          <vs-button class="ml-3 mt-2" @click="saveGroupChanges()">Guardar cambios</vs-button>
                        </div>
                      </div>
                    </div>

                  </vx-card>
                </form>
              </vx-card>
            </template>

            <vx-card v-if="identityIsCompleted || identityVerificationHasExpired || !needIdentityVerification" class="mb-4">
              <h4 class="mb-4">Verificación de historial crediticio</h4> 
              <div class="w-full mb-4 text-justify flex">
                <vs-checkbox v-model="cdcPayload.tyc" :vs-value="1">
                </vs-checkbox>
                <span class="ml-2 mt-1">Acepto el <a href="https://www.redgirasol.com/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a> y <a href="https://www.redgirasol.com/terminos-y-condiciones" target="_blank">Términos y Condiciones</a> y cláusulas de medios electrónicos tales como NIP.</span>
              </div>

              <div class="mb-4">
                <h5>Autorización de Consulta.</h5>
                <div class="w-full">
                  <p class="text-justify">
                    Autorizo expresamente a <strong>Red Girasol S.A.P.I. de C.V. Institución de Financiamiento Colectivo</strong>, para llevar a cabo Investigaciones sobre mi comportamiento crediticio
                    en las Sociedades de Información Crediticia (SIC) que estime conveniente. Conozco la naturaleza y alcance de la información 
                    que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio.
                    Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos
                    relación jurídica. Acepto que este documento quede bajo propiedad de <strong>Red Girasol S.A.P.I. de C.V. Institución de Financiamiento Colectivo y/o SIC Consultada</strong>
                    para efectos de control y cumplimiento del artículo 28 de la LRSIC.
                  </p><br>
                  <div class="mb-base">
                    <div class="vx-row">
                      <div class="vx-col w-full mb-3">
                        <p>
                          Al solicitar el <strong>NIP</strong> recibirás un mensaje de texto (sms) al teléfono celular proporcionado anteriormente; puede tardar hasta 1 minuto en llegar. 
                          <!-- En caso de no haberlo recibido, lo puedes volver a solicitar dando clic <a @click.stop="doRequestCDCNip()" class="clickable-img">aquí</a>. -->
                        </p>
                        <div v-if="showWhatsapp" class="mb-2 mt-2">
                          <p class="bold mb-1">
                            ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
                          </p>
                          <div class="clickable-img" @click.stop="doRequestCDCNip(true)">
                            <vs-chip class="mr-2 bold">Enviar Whatsapp a {{ this.payload.country_calling_code }}{{ anonymousFormatPhone(this.payload.client$phone) }}</vs-chip>
                          </div>
                          <div class="clickable-img" @click.stop="doRequestCDCNip()">
                            <vs-chip class="mr-2 bold">Enviar SMS a {{ this.payload.country_calling_code }}{{ anonymousFormatPhone(this.payload.client$phone) }}</vs-chip>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col sm:w-full mb-2">
                        <vs-button v-if="!nipIsCreated" @click="doRequestCDCNip()" color="primary" class="bold-btn mb-2" type="filled">Recibir NIP</vs-button>
                      </div>
                      <div class="vx-col sm:w-full mt-2 mb-3">
                        <label for="code">Ingresa NIP</label>
                        <vs-input
                          type="text"
                          v-model.lazy="cdcPayload.verification_code"
                          name="code"
                          v-validate="'required|numeric'"
                          maxlength="6"
                          autocomplete="off"
                          v-on:keyup.enter="doAuthorizationCDC()"
                          placeholder="Ingresa NIP" 
                          :disabled="!nipIsCreated"
                          />
                      </div>
                      <div class="vx-col sm:w-full mb-2">
                        <vs-button v-if="nipIsCreated" :disabled="cdcPayload.verification_code == null || cdcPayload.tyc == null || cdcPayload.tyc == false || !personalUpdated" @click="doAuthorizationCDC()" color="primary" class="bold-btn mb-2" type="filled">Autorizo</vs-button>
                      </div>
                    </div>
                  </div>

                  <!-- <small class="mb-3">
                    *** Para enviar la solicitud, primero debes aceptar el <a href="https://www.redgirasol.com/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a> y <a href="https://www.redgirasol.com/terminos-y-condiciones" target="_blank">Términos y Condiciones</a>, así como haber autorizado la consulta.
                  </small> -->
                </div>
              </div>   
            </vx-card>
          </div>
        </tab-content>
  
        <tab-content
          v-else-if="isPartialCreation"
          title="LLenado de pre-solicitud"
          class="mb-5"
          icon="feather icon-briefcase"
          :before-change="savePartialPreproject">

          <form data-vv-scope="step-2" v-if="formatDocField">
            <!-- Formato -->
            <vx-card title="Pre-solicitud para solicitante existente" class="mb-5">
              <p class="mb-3">Solicitante seleccionado: <strong>{{this.partialCreationClientName}}</strong>.</p>
              <p class="mb-4">Se creará una nueva pre-solicitud a partir de los datos ya existentes del solicitante.
                Sin embargo, es necesario que <strong>se llene de nueva cuenta el formato de revisión crediticia y lo agregues firmado y escaneado.
                Puedes descargar el formato aquí:</strong></p>

              <!-- Formatos -->
              <download-button
                v-if="payload.person_type != pmTypeValue"
                title="Círculo de Crédito Persona Física o PFAE"
                actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                :remoteFile="true" />

              <download-button
                v-if="payload.person_type == pmTypeValue"
                title="Círculo de Crédito Persona Moral"
                actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                :remoteFile="true" />

              <div class="vx-row mt-4">
                <document-upload 
                  ref="formato"
                  :f="formatDocField.formField"
                  @on-success="onDocumentUploadSuccess"
                  @on-error="onDocumentUploadError"
                  :automatic="true"
                  :target_id="partialCreationUserId"
                  :doc="formatDocField.documentSet"
                  :skipLoadedActions="true"
                  :initAsEmpty="true"
                  />
              </div>
            </vx-card>
              
          </form>

        </tab-content>

      </form-wizard>
    </vx-card>
    <load-error v-if="failed" />
    <!-- NOTIFICACIÓN -->
    <vs-popup id="prerequest-notification" title="Aviso importante." :active.sync="popupActive" color="#fff">
      <h2>Sobre tus pagos y avance del proyecto.</h2>
      <div>
        <vs-alert v-if="SupplierStatus === 3" color="warning" active="true" style="height:auto">
          Tu perfil esta en <strong>proceso de verificación</strong>. Puedes crear una Pre-Solicitud para que RedGirasol evalúe su viabilidad crediticia. Solo considera que para concluir el proceso de evaluación de la Solicitud deberás tener tu perfil como Proveedor ya verificado por parte de RedGirasol.
        </vs-alert><br>
        <p>
          Antes de completar el envío de la solicitud, es importante que consideres algunos puntos relevantes:
        </p><br>
        <ol class="ml-8 mr-8" style="list-style-type: decimal !important;">
          <li>
            Recibirás el 70% del anticipo una vez que el proyecto haya concluido el fondeo y cuando los contratos 
            correspondientes al <strong>1. Crédito (Anticipo)</strong> y <strong>2. Compraventa (Anticipo)</strong> hayan sido firmados.
          </li>
          <li>
            El monto restante será cubierto al finalizar la instalación, una vez que se hayan comprobado las evidencias
             y después de que hayan sido firmados los contratos correspondientes al <strong>3. Crédito (Finiquito)</strong> y <strong>4. Compraventa (Finiquito)</strong>.
          </li>
        </ol>
       <br>
        <p>
          Para mayor información estaremos para apoyarte a través del chat de la plataforma 
          o en cualquiera de nuestras redes sociales. ¡Sigamos iluminando al país!
        </p><br>
        <vs-button @click="doSaveProject()" color="primary" class="mt-2" type="filled">Enterad@</vs-button>
      </div>
    </vs-popup>
    <!-- NOTIFICACIÓN -->
  </div>
</template>

<script>
import inputHelper from "@components/mixins/inputHelper";
import dateHelper from "@components/mixins/dateHelper";
import formHelper from "@components/mixins/formHelper";
import FormField from "@components/forms/FormField.vue";
import DownloadButton from '@components/supplier/DownloadButton.vue'
import formatHelper from '@components/mixins/formatHelper';
import ApplicantIdentityVerification from "@applicant/widgets/ApplicantIdentityVerification.vue";
import userIdentityHelper from "@/helpers/userIdentityHelper";

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

const sourceTypesOptions = [
  { type: 0, text: "SOLICITANTE NUEVO" },
  { type: 1, text: "SOLICITANTE EXISTENTE" },
];

const personTypeOptions = [
  { type: 0, text: "PERSONA FÍSICA" },
  // { type: 2, text: "PERSONA FÍSICA CON ACTIVIDAD EMPRESARIAL" },
  { type: 1, text: "PERSONA MORAL" }
];
const genderDefs = [
  {k: 1, v: "HOMBRE"},
  {k: 2, v: "MUJER"},
];
const emailNotAvailable = "emailNotAvailable";

export default {
  name: "pre-project",
  mixins: [formHelper, inputHelper, dateHelper, formatHelper, userIdentityHelper],
  components: {
    FormField,
    FormWizard,
    TabContent,
    DownloadButton,
    ApplicantIdentityVerification
  },
  data() {
    return {
      isTypeSelected: false,
      isMounted: false,
      loading: false,
      failed: false,
      errorMssg: "",
      successMssg: "",

      projectIsCreated: false,
      createdProject: {},

      titleBase: "Fase de pre-solicitud",
      title: "Fase de pre-solicitud",
      pTypeSrc: "",

      sourceTypeNew: 0,
      sourceTypeExisting: 1,

      pfTypeValue: 0,
      pfaeTypeValue: 2,
      pmTypeValue: 1,

      person_type_section_id: 25,
      form_id_pf: 9,
      form_id_pfae: 10,
      form_id_pm: 11,

      pfFormatDocId: 16,
      pmFormatDocId: 24,
      pf_person_type: 1,
      pm_person_type: 1,

      form: {},
      
      allSections: [],
      formSections: [],

      documentsFields: [],
      documentsStates: [],

      collections: {
        cities: [],
        states: [],
        countries: [],
        sourceTypes: sourceTypesOptions, 
        personTypes: personTypeOptions,
        genders: genderDefs,
        neighborhoods: [],

        states_application_address: [],
        cities_application_address: [],
        neighborhoods_application_address: [],

        states_application_business_address: [],
        cities_application_business_address: [],
        neighborhoods_application_business_address: []
      },

      clientsList: [],

      selectField: {},

      payload: {
        person_type: null
      },

      existingApplicantId: null,
      canSelectExistingApplicant: null,
      minimumDaysToCreditReview: 180,

      sourceType: null,
      created_id: null,
      errorEmailNotAvailable: null,
      requieredDocs: null,
      uploadedDocs: null,
      succededDocs: null,
      failedDocs: null,

      docsSuccess: null,

      count: 0,
      max: 0,

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      partialCreationConfirmed: null,
      isPartialCreation: null,
      formatDocField: null,
      partialCreationClientName: null,
      partialCreationUserId: null,

      slas: {
        min_applicant_age: null,
        max_applicant_age: null,
        max_first_project_supplier_total_cost_mxn: null,
        min_financed_amount_mxn: null,
        max_applicant_pf_financed_amount_udis: null,
        max_financed_amount_mxn: null,
      },
      maxUdisPF:0,
      udis: null,
      popupActive: false,
      continue: false,
      pfae_tax_profile: 1,
      potentialApplicants: [],
      requestTypeSelected: 1,
      status: null,
      identityIsCompleted: false,
      current_user_id: null,
      metamapPopUpActive: false,
      cdcPayload: {
        person_type: null,
        tyc: null
      },
      code: null,
      nip: null,
      nipIsCreated: false,
      totalTime: 60,
      loadingTextTimed: ``,
      identityVerificationHasExpired: false,
      statusMetamap: null,
      createdClient: null,
      personalUpdated: false,
      crowdfunding_loan_type_pf: 1,
      crowdfunding_loan_type_pfae_pm: 2,
      nipCounter: 0,
      uniquePhoneResponse: null,
      needIdentityVerification: false,
      showWhatsapp: false,
      whatsappTime: 30
    };
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getClientsList();
    await this.getSelectField();
    this.getPGMPotentialApplicants();
    this.calculateMaxUdisPF();
    this.isMounted = true;
    this.loading = false;
    if(!this.canSelectExistingApplicant){
      let wiz = this.$refs['wizard'];
      wiz.changeTab(0, 1);
    }
    this.$store.subscribe(mutation => {
      if(mutation.type == 'SET_MATI_PROCESS_IS_FINISHED'){
        this.initIdentityVerificationTimer();
      }
    });
  },
  computed: {
    has_pgm_potential_applicants() {
      return this.potentialApplicants.length > 0;
    },
    pgm_potential_applicants_options() {
      return this.potentialApplicants.map((pa) => ({ value: pa.id, text: `${pa.customer_uid} | ${pa.customer_name} | ${pa.owner_name}` }));
    },
    is_legal_representative_owner() {
      return this.payload.business$legal_representative_is_owner;
    },
    should_show_address_document() {
      return this.payload.business$legal_representative_is_owner;
    },
    is_legal_representative_not_owner() {
      return !this.payload.business$legal_representative_is_owner;
    },
    should_show_is_legal_rep_owner_question() {
      return true;
    },
    secondTabTitle(){
      if(this.$refs["wizard"] && this.$refs["wizard"].activeTabIndex == 0 ){
        return "Paso 2";
      }
      else {
        if(this.sourceType == this.sourceTypeExisting){
          return "Selección del solicitante"
        }
        else {
          return "Selección del tipo de solicitante"
        }
      }
    },
    currentProjectId(){
      return null;
    },
    currentClientUserId(){
      return null;
    },
    should_fill_owner_bdate_gender(){
      return true; // se considera que el curp no esta condicionado por la nacionalidad
      //return this.payload.owner$citizenship == this.mexicoCountryId || this.payload.owner$citizenship == null ? true : false;
    },
    should_fill_owner_rfc_curp_fiscal_rec(){
      return true; // se considera que el curp no esta condicionado por la nacionalidad
      //return this.payload.owner$citizenship == this.mexicoCountryId || this.payload.owner$citizenship == null ? true : false;
    },
    is_project_view(){
      return false;
    },
    is_pf_and_tax_profile_one(){
      return false;
    },
    applicant_is_pf(){
      return this.payload.person_type == 0;
    },
    client_personal() {
      return this.createdClient.user.personal;
    },
    should_block_email() {
      return this.requestTypeSelected == 0 ? this.current_user_id != null : false ;
    },
    should_block_phone() {
      return this.requestTypeSelected == 0 ? this.current_user_id != null : false ;
    }
  },
  watch: {
    loading: function(newVal, oldVal) {
      if (newVal == true) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    },  
    totalTime: function(newVal, oldVal) {
      if(newVal == 20) {
        this.getCreatedClientInfo();
        if (this.client_personal.curp != null && this.client_personal.curp != "") { 
          this.personalUpdated = true;
          this.totalTime = 0;
        }
      } 
    },
    nipCounter: function(newVal, oldVal) {
      if (newVal > 2) {
        // Decir que mejor vaya con una solicitud normal.
        this.formSubmittedExpress();
      }
    }
  },
  methods: {
    initIdentityVerificationTimer(){
      this.totalTime = 60;
      this.text = `Sólo nos toma ${this.totalTime} segundos obtener esta información. `
      this.showLoading(true, this.text);
      let loading = document.getElementsByClassName("title-loading");
      let timer = setInterval(() => {
          this.totalTime-=1;
          this.text = `Sólo nos toma ${this.totalTime} segundos obtener esta información. `
          loading[0].innerText = this.text;
          if(this.totalTime == 0){
            this.identityVerificationHasExpired = true;
            clearInterval(timer);
            this.showLoading(false);
          }
        }, 1000);
    },
    async getPGMPotentialApplicants(){
      try {
        const res = await axios.get(`/api/kof/get/loan-request/potential-applicants/${this.SupplierId}`);
        this.potentialApplicants = res.data;
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
    },
    async formSubmitted(){
      let mssg = "Tu proyecto ha sido creado, y está en revisión para ser pre-aprobado. Enviaremos un correo a tu cliente para informarle que le estas generando una pre-solicitud de crédito para que nos ayude proporcionado sus datos y avance en la pre-solicitud. ";
      mssg += `El ID de tu proyecto es: ${this.created_id}.`;
      this.$vs.dialog({
        color: "success",
        type: "confirm",
        title: "Pre-solicitud creada correctamente",
        text: mssg,
        acceptText: "Ver solicitud",
        cancelText: 'Finalizar',
        accept: this.goToProject,
        cancel: this.finish,
        close: this.finish,
      })
    },
    async formSubmittedExpress(){
      let mssg = "Por el momento no podemos continuar con la solicitud exprés, te invitamos a continuar con una pre-solicitud tradicional y enviarla a revisión. ";
      mssg += `El ID de tu proyecto es: ${this.created_id}.`;
      this.$vs.dialog({
        color: "success",
        type: "confirm",
        title: "Pre-solicitud creada correctamente",
        text: mssg,
        acceptText: "Ver solicitud",
        cancelText: 'Finalizar',
        accept: this.goToProject,
        cancel: this.finish,
        close: this.finish,
      })
    },
    async validateSourceType(){
      return await new Promise(async (resolve, reject) => {
        this.loading = true;
        let result = await this.$validator.validateAll('step-0');
        if (result) {
          if(this.sourceType == this.sourceTypeNew){
            // proyecto para cliente nuevo
            this.title = `${this.titleBase}: solicitante nuevo`;
            resolve(true);
          }
          else{
            // proyecto para cliente existente
            this.title = `${this.titleBase}: solicitante existente`;
            resolve(true);
          }
        } else {
          this.warningNotif("Selección de tipo de solicitante", 
          "Por favor elije si deseas crear el proyecto para un nuevo solicitante o uno existente");
          reject("correct all values");
        }
        this.loading = false;
      })
    },
    async validateSelectType() {
      return await new Promise(async (resolve, reject) => {
        this.loading = true;
        this.isPartialCreation = null;
        
        if(this.sourceType == this.sourceTypeExisting){
          if (this.existingApplicantId != null) {
            let r = await this.runClientValidation();
            if(!r){
              reject("not");
            }
            else {
              // obtener los datos de los documentos 
              await this.getFormInfoForPartialCreation();
              resolve(true);
            }
          }
          else {
            this.warningNotif("Selección del solicitante", "Por favor selecciona al cliente");
            reject("correct all values");
          }
        }
        else{
          if (this.payload.person_type != null) {
            if(this.applicant_is_pf && this.requestTypeSelected == null) {
              this.warningNotif("Selección de tipo de solicitud", "Por favor selecciona la opción que defina el tipo de solicitud a realizar.");
              reject("not");
            }
            await this.getFormInfo();
            this.pTypeSrc = this.collections.personTypes.find(f => f.type == this.payload.person_type).text;
            this.title = `${this.titleBase}: ${this.pTypeSrc} `;
            resolve(true);
          } 
          else {
            this.warningNotif("Selección de tipo de solicitante", "Por favor selecciona la opción que defina a tu cliente");
            reject("correct all values");
          }
        }
        this.loading = false;
      })
    },
    async confirmPartialCreation(sel, explanation){
      this.loading = false;
      this.partialCreationConfirmed = null;

      this.$vs.dialog({
        color: "warning",
        type: "confirm",
        title: "Creación de pre-solicitud",
        text: `Confirme que desea crear una pre-solicitud. ${explanation}`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        cancel: () => {this.partialCreationConfirmed = false},
        accept: () => {this.partialCreationConfirmed = true},
      });

      await (async() => {
        while(this.partialCreationConfirmed == null) {
          await this.sleep(100);
        }
      })();
      
      if(this.partialCreationConfirmed){
        this.isPartialCreation = true;
      }

      return this.partialCreationConfirmed;
    },
    confirmFullCreation(sel){
      this.$vs.dialog({
        color: "warning",
        type: "confirm",
        title: "Creación de pre-solicitud",
        text: `Confirme que desea crear una solicitud para el cliente ${sel.fullname}. Todos los datos se llenarán en base a la información provista en el pasado.`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        accept: this.createFullPreproject,
      });
    },
    async createFullPreproject(){
      this.loading = true;
      try {
        let payload = { client_id: this.existingApplicantId };
        let res = await axios.post('/api/v2/projects/createProjectForApplicant', payload);
        if(res.data.success == true){
          this.created_id = res.data.id;
          await this.formSubmitted();
        }
        else {
          this.warn(res.data.error);
          this.errorMssg = res.data.error;
        }
      }
      catch (error) {
        this.warn(err);
        this.failedOperationNotif();
      }
      this.loading = false;
    },

    async runClientValidation(){
      try {
        let sel = this.clientsList.find(f => f.id == this.existingApplicantId);        
        let res = await axios.post(`/api/v1/supplier/${this.SupplierId}/checkExistingClientForProject`, {client_id: sel.id});
        const explanation = res.data.explanation.replace('%name%', sel.fullname);
        if(res.data.result == 'failed') {
          this.$vs.dialog({
            color: "danger",
            title: "Creación de solicitud",
            text: `${explanation}`,
            acceptText: "Cerrar",
          });

          return false;
        }
        else if(res.data.result == 'partial-passed') {
          return await this.confirmPartialCreation(sel, explanation);
        }
        else if(res.data.result == 'full-passed') {
          this.confirmFullCreation(sel, explanation);
        }

      } catch (error) {
        this.warn(error);
        return false;
      }
    },

    async savePreproject() {
      if(this.continue){
        return await new Promise(async (resolve, reject) => {
          let result = await this.runValidations();
          if(!result){
            this.missingFieldsNotif();
            reject("");
          }
          else{
            // Mandar a guardar para disparar la validación con Metamap
            let loadingMessage = "Generando pre-solicitud, por favor no recargues la página...";
            if (this.requestTypeSelected == 0) {
              loadingMessage = "";
            }
            this.showLoading(true, loadingMessage);
            let res = await this.saveFormInfo();
            this.showLoading(false);
            if(res == true){
              resolve(true);
              if (this.requestTypeSelected != 0) {
                this.formSubmitted();
              } else {
                let wFooter = document.getElementsByClassName("wizard-card-footer");
                wFooter[0].style.display = "none";
              }
            }
            else {
              if(this.errorEmailNotAvailable){
                // mostrar error sobre correo ya utilizado
                let text = 'El correo ingresado ya está registrado en RedGirasol, por lo que es necesario ingresar una dirección diferente.';
                text += "\n Si tienes alguna duda al respecto, por favor comunícate con nosotros a través del Chat."
                this.$vs.dialog({
                  color: 'danger',
                  title: "No es posible ejecutar el registro",
                  text: text,
                  acceptText: "Aceptar",
                });
              }
              else{
                this.failedOperationNotif(null, this.errorMssg);
              }
              reject("");
            }
          }
          this.continue = false;
        });
      } else {
        this.popupActive = true;
      }
    },
    doSaveProject(){
      this.popupActive = false;
      this.continue = true;
      this.savePreproject();
    },
    async savePartialPreproject(){
      return await new Promise(async (resolve, reject) => {
        // let r = await this.$refs.formato.checkForNewFileSaved();
        // if(!r){
        //   this.missingFieldsNotif("Documento pendiente", "Por favor agrega el documento antes de continuar");
        //   reject("");
        // }
        // else {
          try {
            let payload = { client_id: this.existingApplicantId };
            let res = await axios.post('/api/v2/projects/createProjectForApplicant', payload);
            if(res.data.success == true){
              this.created_id = res.data.id;
              await this.formSubmitted();
            }
            else {
              this.warn(res.data.error);
              this.errorMssg = res.data.error;
            }
          }
          catch (error) {
            this.warn(err);
            this.failedOperationNotif();
          }
        // }
   
      })
    },
    async runValidations(isForExpress = false) {
      // validar componentes tipo FormField
      let res1 = await this.validateFormFields("step2");
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll('step-2');
      if(!res2){
        return false;
      }

      // validaciones especiales
      let res3 = await this.checkFormFieldsSpecialValidations("step2");
      if(!res3){
        return false;
      }

      if(this.requestTypeSelected != 0) {
        // verificacion de documentos cargados
        // let resd = await this.validateDocuments();
        // if(!resd){
        //   this.missingFieldsNotif("Documentos pendientes", "Es necesario que cargues todos los documentos solicitados para poder enviar la pre-solicitud", 10000);
        //   return false;
        // }
      }

      return true;
    },
    async validateFormFields(gslug){
      let allValid = true;
      let grefs = this.$refs[gslug];
      for(let p in grefs){
        let r = await this.$refs[gslug][p].validate();
        if(r == false){
          allValid = false;
        }
      }
      return allValid;
    },
    async validateDocuments(){
      let allValid = true;
      this.$refs.documents = this.$refs.documents.filter((current) => {
          if(this.is_legal_representative_not_owner)  {
            if(current.doc.id == 31 || current.doc.id == 23) 
              return false;
          }
          return true;
      });
      let docs = this.$refs.documents;
        for(let dd in docs){
          
          // const current = this.$refs.documents[dd];
          // if(this.is_legal_representative_not_owner)  {
          //   if(current.doc.id == 31 || current.doc.id == 23) 
          //     continue; 
          // }

          if(this.payload.person_type == this.pfTypeValue && this.$refs.documents[dd].f.fname === "fiscal_record_df_id"){
            continue;
          } else {
            let r = await this.$refs.documents[dd].checkForNewFileLoaded();
            if(r == false){
              allValid = false;
            }
          }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let sp = f.specialValidation();
        if(sp != null && sp.method in this){
          let res = await this[sp.method]();
          let n = f.rawFieldName
          if(f.f.db_field === "rfc") {
            if(res.length === 0) {
              continue;
            } else {
              spvalid = false;
              res.forEach(item => {
                this.errorNotif(item.n, item.e, 10000);
              })
            }
          }
          if(!res){
            spvalid = false;
            if(sp.custom_error){
              let err = this[sp.custom_error]();
              f.setError(err);
              this.errorNotif(n, err, 10000);
            }
            else {
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
            }
            continue;
          }
        }
      }

      return spvalid;
    },

    async validate_project_finances(){
      let cost = this.payload.total_cost;
      let count = 0;
      let milis = 15000;
      try {
        let res = await axios.get(`/api/v1/supplier/${this.SupplierId}/getSupplierFinishedProjectsCount`);
        count = res.data;
      } catch (error) {
        this.warn(error);
      }

      if(count < 1 && cost > parseInt(this.slas.max_first_project_supplier_total_cost_mxn) ){
        let pfsla = this.mxnFormat(this.slas.max_first_project_supplier_total_cost_mxn);
        this.errorNotif("Costo del proyecto", "El costo máximo a financiar para instaladores sin proyectos finalizados es de " + pfsla, milis);
        return false;
      }

      if(cost < parseInt(this.slas.min_financed_amount_mxn)){
        let minf = this.mxnFormat(this.slas.min_financed_amount_mxn);
        this.errorNotif("Costo del proyecto", "El costo mínimo del proyecto es de " + minf, milis);
        return false;
      }
      
      let maxudis = parseInt(this.slas.max_applicant_pf_financed_amount_udis);
      let fixudis = parseFloat(this.udis).toFixed(7);
      let maxpfmxn = maxudis * fixudis;

      // if (this.applicant_is_pf && cost > maxpfmxn) {
      //   this.errorNotif("Costo del proyecto",
      //       "El costo máximo a financiar para personas físicas es de " + this.format_price(maxudis, 0) +
      //       " UDIS, que corresponde a " + this.mxnFormat(maxpfmxn), milis);
      //     return false;
      // }
      // if(this.payload.person_type == this.pfTypeValue && this.payload.crowdfunding_loan_type != this.crowdfunding_loan_type_pfae_pm && cost > maxpfmxn){
      //   this.errorNotif("Costo del proyecto",
      //       "El costo máximo a financiar para personas físicas es de " + this.format_price(maxudis, 0) +
      //       " UDIS, que corresponde a " + this.mxnFormat(maxpfmxn), milis);
      //     return false;
      // }

      // if(this.payload.person_type == this.pfTypeValue && this.payload.crowdfunding_loan_type == this.crowdfunding_loan_type_pfae_pm && cost > parseInt(this.slas.max_financed_amount_mxn)){
      //     let maxf = this.mxnFormat(this.slas.max_financed_amount_mxn);
      //     this.errorNotif("Costo del proyecto",
      //       "El costo máximo a financiar para personas físicas con actividad empresarial es de " + maxf, milis);
      //     return false;
      // }

      if(this.payload.person_type == this.pmTypeValue && cost > parseInt(this.slas.max_financed_amount_mxn)){
        let maxm = this.mxnFormat(this.slas.max_financed_amount_mxn);
        this.errorNotif("Costo del proyecto",
          "El costo máximo a financiar para personas morales es de " + maxm, milis);
        return false;
      }
      return true;
    },
    async saveFormInfo(){
      let ok = false;
      this.errorEmailNotAvailable = false;
      try{
        let payload = this.packNested(this.payload);
        payload.request_type_selected = this.requestTypeSelected;
        let res = await axios.post('/api/v2/projects/createPreproject', payload);
        if(res.data.success == true){
          this.created_id = res.data.id;
          this.current_user_id = res.data.user_id;

          // enviar documentos
          await this.getCreatedProjectInfo();
          this.projectIsCreated = true;

          if(this.requestTypeSelected != 0) {
            ok = true;
            // await this.sendAllDocuments();
  
            // if(this.docsSuccess){
            //   ok = true;
            // }
            // else{
            //   ok = false;
            //   this.errorNotif("Error al cargar archivos", 
            //   "Uno o más archivos han tenido un problema al cargarse, por favor corrobora que los formatos sean los correctos e intenta de nuevo", 10000);
            // }
          } else {
            this.askForIdentityVerification();
            ok = true;
          }
        }
        else {
          this.warn(res.data.error);
          ok = false;
          if(res.data.error == emailNotAvailable){
            this.errorEmailNotAvailable = true;
          }
          else{
            this.errorMssg = res.data.error;
          }
        }
      }
      catch(e){
        this.warn(e.response);
        ok = false;
        let err = e.response.data ? e.response.data.error : null;
        if(err == emailNotAvailable){
            this.errorEmailNotAvailable = true;
          }
          else{
            this.errorMssg = err;
          }
      }
      return ok;
    },
    async getCreatedProjectInfo(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=client.user";
        let response = await axios.get(`/api/v2/projects/${this.created_id}?${params}`);
        this.createdProject = response.data;
      }
      catch(e){
        this.warn(e);
      }
    },    
    async getCreatedClientInfo(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=client.user.personal";
        let response = await axios.get(`/api/v2/projects/${this.created_id}?${params}`);
        this.createdClient = response.data;
      }
      catch(e){
        this.warn(e);
      }
    },
    async sendAllDocuments(){
      this.uploadedDocs = 0;
      this.succededDocs = 0;
      this.failedDocs = 0;
      let docs = this.$refs.documents;
      this.requieredDocs = docs.length;
      for(let dd in docs){
        if(this.payload.person_type == this.pfTypeValue && this.$refs.documents[dd].f.fname === "fiscal_record_df_id"){
          this.requieredDocs = this.requieredDocs -1;
          continue;
        } else {
          let doc = this.$refs.documents[dd];
          let set = doc.getDocSet();
          let target = this.docTargetEvaluator(set.clasification, set.clasification_2);
          doc.saveFileWithTarget(target);
        }
      }
      this.count = 0;
      this.max = this.requieredDocs * 25;
      let stopped = false;

      await (async() => {
        while(!stopped) {
          await this.sleep(500);
          if(this.uploadedDocs == this.requieredDocs){
            stopped = true;
          }
          this.count += 1;
          if (this.count >= this.max) stopped = true // stop after 10 secs per document cycles
        }
      })();

      this.docsSuccess = (this.uploadedDocs == this.requieredDocs && this.failedDocs == 0);
      return this.docsSuccess;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getCollections() {
      try {
        let collectionsObjects = ['countriesList', 'statesList', 'banxicoUdis'];

        let params = `with[]=${collectionsObjects.join("&with[]=")}`;
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.countries = res.data.countriesList;
        // this.collections.states = res.data.statesList;
        this.udis = res.data.banxicoUdis.udis;

        let slasObjects = ['min_applicant_age', 'max_applicant_age', 'max_first_project_supplier_total_cost_mxn', 'min_financed_amount_mxn', 'max_applicant_pf_financed_amount_udis', 'max_financed_amount_mxn'];
        let slasParams = "with[]=" + slasObjects.join("&with[]=");
        let slasRes = await axios.get(`/api/v1/forms/getSlasValues?${slasParams}`);
        this.slas = slasRes.data;

      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async getClientsList(){
      try{
        let response = await axios.get(`/api/v1/supplier/${this.SupplierId}/getSupplierAvailableClients`);
        this.clientsList = response.data;
        if(this.clientsList.length < 1){
          this.sourceType = this.sourceTypeNew;
          this.canSelectExistingApplicant = false;
        }
        else {
          this.canSelectExistingApplicant = true;
        }
      }
      catch(e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async getSelectField(){
      try{
        let res = await axios.get("/api/v1/forms/getSectionFields/" + this.person_type_section_id);
        this.selectField = res.data.section.fields[0];
        this.formFieldSetter(this.selectField, this, true);
      }
      catch(e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfoForPartialCreation(){
      try {
        let c = this.clientsList.find(f => f.id == this.existingApplicantId);
        this.partialCreationClientName = c.fullname;
        this.partialCreationUserId = c.user_id;
        this.payload.person_type = (c.person_type == this.pf_person_type) ? this.pfTypeValue : this.pmTypeValue;
        await this.getFormInfo();
        let doc_id = (c.person_type == this.pf_person_type) ? this.pfFormatDocId : this.pmFormatDocId;
        this.formatDocField = this.documentsFields.find(f => f.documentSet.id == doc_id);
      } catch (error) {
        this.warn(error);
      }
    },
    async getFormInfo(){
      try{
          let formId = null;
          switch (this.payload.person_type) {
            case this.pfTypeValue:
              formId = this.form_id_pfae;
              break;
            case this.pfaeTypeValue:
              formId = this.form_id_pfae;
              break;
            case this.pmTypeValue:
              formId = this.form_id_pm;
              break;
            default:
              break;
          }
      
        let response = await axios.get("/api/v1/forms/getFormFields/" + formId);
        this.form = response.data.flow;
        this.allSections = this.handleFinanciableSection(response.data.sections);
        this.formSections = [];
        this.documentsFields = [];

        let url = this.ApiDomain + `/storagev3/documents/form/${formId}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;
        
        let vFieldsCount = 0;

        this.allSections.forEach(s => {

          if(s.id == 142 || s.id == 143 || s.id == 144 || s.id == 145 || s.id == 146)
            return;

          vFieldsCount = 0;
          s.fields.forEach(f => {
           this.formFieldSetter(f, this, true);
           if(this.notDocumentNorPrivate(f)){ vFieldsCount += 1;}

           // check for documents
            if(f.doc_id != null){

              // if(s.id == 27 && f.doc_id == 23)
              //   return;

              let ff = {formField: f, documentSet: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                ff.documentSet = ss.documents.find(d => d.id == f.doc_id);
              }
              this.documentsFields.push(ff);
            }
          })

          if(vFieldsCount > 0){
            this.formSections.push(s);
          }
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    handleFinanciableSection(sections){
      if(this.payload.person_type === this.pfTypeValue){
        let section47 = sections.pop();
        sections.unshift(section47);
      }
      return sections;
    },
    goToProject() {
      this.$router.replace({
        name: "detalleProyecto",
        params: { id: this.created_id }
      });
    },
    finish(){
       this.$router.replace({
        name: "misProyectos"
      });
    },
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
      if(condition != null && condition in this){
        return this[condition];
      }
    },

     /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(this.projectIsCreated){
        if(cls == 'user' && cls_2 == 'applicant'){
          return this.createdProject.client.user.id;
        }
        else if(cls == 'project') {
          return this.created_id;
        }
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = this.payload.address$state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted) this.payload.address$city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },

    async reload_business_cities(){
      try {
        let sid = this.payload.businessAddress$state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted) this.payload.businessAddress$city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async calculateMaxUdisPF(){
      try {
        let res = await axios.get(`/api/v1/forms/calculateMaxUdisPF`);
        this.maxUdisPF = res.data.max_udis_pf;
      } catch (e) {
        this.warn(e);
      }
    },

    /* validaciones especiales */
    validate_client_age_legal(){
      let years = this.calculateAgeFromDate(this.payload.client$birth_date);
      let min = parseInt(this.slas.min_applicant_age);
      let max = parseInt(this.slas.max_applicant_age);
      return years >= min && years <= max;
    },
    client_birthdate_invalid(){
      return `El solicitante debe tener entre ${this.slas.min_applicant_age} y ${this.slas.max_applicant_age} años`;
    },

    async validate_client_unique_curp(){
      return await this.validate_unique_curp(this.payload.client$curp, null)
    },
    async validate_owner_unique_curp(){
     return await this.validate_unique_curp(this.payload.owner$curp, null)
    },    
    async validate_client_unique_rfc(){
     return await this.validate_unique_personal_rfc(this.payload.client$rfc, null)
    },
    async validate_owner_unique_rfc(){
     return await this.validate_unique_personal_rfc(this.payload.owner$rfc, null)
    },
    async validate_business_unique_rfc(){
      return await this.validate_unique_business_rfc(this.payload.business$rfc, null)
    },

    onDocumentUploadSuccess(){    
      this.uploadedDocs += 1;
      this.succededDocs += 1;
    },
    onDocumentUploadError(){
      this.uploadedDocs += 1;
      this.failedDocs += 1;
    },
    owner_curp_conditions_changed(){
      this.trigger_curp_conditions_changed("owner");
    },

    client_curp_conditions_changed(){
      this.trigger_curp_conditions_changed("client");
    },

    trigger_curp_conditions_changed(obj){
      let c = this.payload[`${obj}$curp`];
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);
        
        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.payload[`${obj}$birth_date`] = null;
        }
        else{
          this.payload[`${obj}$birth_date`] = date;
        }
      }
      else{
        this.payload[`${obj}$birth_date`] = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.payload[`${obj}$gender`] = 1;
        }
        else if(g == "M"){
          this.payload[`${obj}$gender`] = 2;
        }
        else{
          this.payload[`${obj}$gender`] = null;
        }
      }
      else{
        this.payload[`${obj}$gender`] = null;
      }
    },
    verify_zip_code_application_address() {
      this.verifyPayloadZipCode(this.payload, {
        addressName: 'address', 
        statesArray: 'states_application_address', 
        citiesArray: 'cities_application_address', 
        neighborhoodsArray: 'neighborhoods_application_address'
      });
    },
    
    verify_zip_code_application_business_address() {
      this.verifyPayloadZipCode(this.payload, {
        addressName: 'businessAddress', 
        statesArray: 'states_application_business_address', 
        citiesArray: 'cities_application_business_address', 
        neighborhoodsArray: 'neighborhoods_application_business_address'
      });
    },
    async validate_identity_verification(){
      this.statusMetamap = await this.getUserIdentityStatus(this.current_user_id);
      this.identityIsCompleted = this.verificationIsCompleted(this.statusMetamap);

      return this.identityIsCompleted;
    },
    async validate_identity_verification_error(){
      if(!this.identityIsCompleted){
        return "La verificación de identidad con Mati no ha sido completada.";
      }
    },

    async doRequestCDCNip(isWhatsapp = false)
    {
      try {
        const res = await axios.post(`/api/v2/projects/${this.UserId}/send-cdc-nip?whatsapp=${isWhatsapp}`);
        this.nipIsCreated = true;
        if(!this.showWhatsapp) {
          this.initNipCounter(30);
        }
        this.saveSuccessNotif(null, `Te hemos enviado un código por mensaje de texto al siguiente número: ${this.payload.country_calling_code} ${this.anonymousFormatPhone(this.payload.client$phone)}`);
        this.nipCounter++;
      } catch (error) {
        this.warn(error);
      }
    },

    initNipCounter() {
      let timer = setInterval(() => {
        --this.whatsappTime;
        if(this.whatsappTime == 0){
          this.showWhatsapp = true;
          clearInterval(timer);
        }
      }, 1000);
    },

    async doAuthorizationCDC()
    {
      try {
        this.showLoading(true, "Gracias por autorizar la consulta, en breve tendremos una respuesta para ti...");
        const payload = {
          user_id: this.current_user_id
          , verification_code: this.cdcPayload.verification_code
          , project_id: this.created_id
        };
        const res = await axios.post(`/api/v2/projects/authorize-cdc-request`, payload);
        // console.log(res);
        if(!res.success){
          this.nipIsCreated = false;
          let error = res.data.error;
          this.missingFieldsNotif(null, error);
          this.showLoading(false);
        } else {
          this.showLoading(false);
          this.formSubmitted();
        }
      } catch (error) {
        this.showLoading(false);
        this.formSubmittedExpress();
      }
    },
    async saveGroupChanges(){
      return await new Promise(async (resolve, reject) => {
          let result = await this.runValidations(true);
          if(!result){
            this.missingFieldsNotif();
            reject("");
          } else {
            // Mandar a guardar para disparar la validación con Metamap
            let loadingMessage = "Actualizando información del cliente, por favor no recargues la página...";
            // this.showLoading(true, loadingMessage);
            let payload = this.packNested(this.payload);

            if(this.objectIsEmpty(payload)){
              this.saveSuccessNotif("Operación exitosa"
              , "Información actualizada correctamente. ¡No olvides enviar la solicitud completa! Una vez que concluyas con el proceso, da clic en el botón “Enviar solicitud completa” en el detalle del proyecto para concluir con el proceso."
              , 6000);
            }

            try {
              // ejecutar guardado
              await axios.put(`/api/v2/projects/${this.current_user_id}/deepUpdatePersonal`, payload);
              this.personalUpdated = true;
              resolve(true);
              this.showLoading(false);
              this.saveSuccessNotif("Operación exitosa"
              , "Información actualizada correctamente. ¡No olvides enviar la solicitud completa! Una vez que concluyas con el proceso, da clic en el botón “Enviar solicitud completa” en el detalle del proyecto para concluir con el proceso."
              , 6000);
              this.identityVerificationHasExpired = true;
            }
            catch (error) {
              this.showLoading(false);
              console.log(error);
              this.failedOperationNotif(null, null);
            }

          this.loading = false;
        }
      });     
    },
    async validate_client_unique_phone() {
      return true; //ddimas
      if (this.requestTypeSelected == 0) {
        try {
          const payload = {
            phone: this.payload.client$phone
          };
  
          const res = await axios.post(`/api/v1/forms/validate-unique-phone`, payload);
          this.uniquePhoneResponse = res.data;
          let flag = !this.uniquePhoneResponse.phone_exists; 
  
          if (!flag && this.uniquePhoneResponse.user_id == this.current_user_id) {
            flag = true;
          }
          return flag;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
      return true;
    },
    validate_client_unique_phone_error() {
      const roles = this.uniquePhoneResponse.roles;
      let txt = "Parece que el teléfono que proporcionaste ya está registrado, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso."
      for (let index = 0; index < roles.length; index++) {
        const element = roles[index];
        
        if(element == "client") {
          txt = "Este teléfono ya pertenece a otro Solicitante, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso.";
        } 
        
        if(element == "supplier" || element == "supplier_agent") {
          txt = "Ya tenemos registrado este teléfono y no pertenece al Solicitante. Por favor introduce el teléfono del Solicitante.";
        }
      }

      return txt;
    },
    askForIdentityVerification(){
      this.$vs.dialog({
        color: "warning",
        type: "confirm",
        title: "El siguiente paso",
        text: `Hemos generado la pre-solcitud con ID <<${this.created_id}>> y la puedes consultar desde tu lista de proyectos. 
              Para la solicitud exprés necesitamos que el solicitante realice la verificación de identidad con Metamap, ¿deseas realizarla en este momento? Realizarla acelera el proceso para la aprobación del crédito.`,
        acceptText: "Sí, realizar la verificación de identidad",
        cancelText: 'No, en otro momento',
        accept: this.doContinueWithIdentityVerification,
      });
    },
    doContinueWithIdentityVerification () {
      this.needIdentityVerification = true;
    },
    showFormFields(dbField) {
      if(!this.applicant_is_pf) {
        if(
          dbField === "total_cost" ||
          dbField === "phone" ||
          dbField === "country_calling_code" ||
          dbField === "email" ||
          dbField === "first_name" ||
          dbField === "second_name" ||
          dbField === "last_name_1" ||
          dbField === "last_name_2" ||
          dbField === "name" ||
          dbField === "rfc"
        ) {
          return true;
        }
      } else {
        if(
          dbField === "total_cost" ||
          dbField === "phone" ||
          dbField === "country_calling_code" ||
          dbField === "email" ||
          dbField === "first_name" ||
          dbField === "second_name" ||
          dbField === "last_name_1" ||
          dbField === "last_name_2" 
        ) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style >
  .rg-title {
    font-size: 3rem !important;
  }
  #notification .vs-popup--header {
    background: #28DE18 !important;
  }
  #notification .vs-popup--header .vs-popup--title h3{
    color: #fff !important;
  }

  .vs-radio--label {
    line-height: 2 !important;
  }
</style>